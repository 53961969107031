<template>
    <div>

        <cHeaderBuyer activeNav = "atc"></cHeaderBuyer>
        <div class = "body">
              <div class = "page-wrap">
                    <cAtcMenu></cAtcMenu>

 <div class = "col-right">
        <div class = "container">
            <div class = "nav-title">修改帐户信息</div>
            <a-spin :spinning="spinning">
            <a-form :label-col = "{ span: 5 }" :wrapper-col = "{ span: 12 }">
                <a-row>
                    <a-col :span = "18" :offset = "4">
                        <a-form-item label = "头像" required
                        >
                            <div slot = "extra">（支持JPG、GIF、PNG、JPEG、BMP格式，文件小于2M）</div>
                            <a-upload
                                name = "avatar"
                                list-type = "picture-card"
                                class = "avatar-uploader"
                                :show-upload-list = "false"
                                :action="uploadUrl"
                                :on-success = "handleAvatarSuccess"
                                :before-upload = "beforeAvatarUpload"
                                @change = "changeAvatar"
                            >
                            <img v-if = "userInfo.avatar" :src = "userInfo.avatar" alt = "avatar" />
                            <div v-else>
                              <a-icon :type = "uploadLoading ? 'loading' : 'plus'" />
                              <div class = "ant-upload-text">
                                点击上传
                              </div>
                            </div>
                          </a-upload>
                        </a-form-item>
                    </a-col>
                    <a-col :span = "18" :offset = "4">
                        <a-form-item label = "用户名">{{ userInfo.username }}</a-form-item>
                    </a-col>
                    <a-col :span = "18" :offset = "4">
                        <a-form-item label = "手机号">{{ userInfo.mobile }}</a-form-item>
                    </a-col>
                    <a-col :span = "18" :offset = "4">
                        <a-form-item label = "昵称" required>
                            <a-input v-model = "userInfo.nickname"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span = "18" :offset = "4">
                        <a-form-item label = "性别" required>
                            <a-select v-model="userInfo.gender_desc" @change = "changeGender">
                              <a-select-option value = "0">
                                未知
                              </a-select-option>
                              <a-select-option value = "1">
                                男
                              </a-select-option>
                              <a-select-option value = "2">
                                女
                              </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span = "18" :offset = "4">
                        <a-form-item label = "生日" required>
                           <a-date-picker @change = "changeBirthday" :locale = "locale"
                                          v-model = "userInfo.birthday" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <div class = "modal-footer">
                    <a-button>取消</a-button>
                    <a-button class = "last-child" @click = "saveUserInfo">保存</a-button>
                </div>
            </a-form>
            </a-spin>
        </div>
    </div>
              </div>
        </div>
    </div>

</template>

<script>
import service from "../../../utils/request";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import moment from "moment";
import 'moment/locale/zh-cn';

import cHeaderBuyer from "@/component/header-buyer";
import cAtcMenu from "@/component/atc-menu";


let baseUrl = process.env.VUE_APP_BASE_URL
export default {
    name: "mdyUserInfo",
    components: {
        cHeaderBuyer,
        cAtcMenu
    },
    data() {
        return {
            moment,
            locale,
            uploadLoading: false,
            uploadUrl: baseUrl + '/api/merch/service/uploadImg',
            userInfo: {},
            spinning: false,
        }
    },
    created() {
        this.getAccount();
    },
    methods: {
        getAccount() {
            service.post(service.uri.user.getUserInfo).then((res) => {
                this.userInfo = res.data
            });
        },
        changeAvatar(e) {
            console.log(e)
        },
        changeGender(e) {
            this.userInfo.gender = e;
        },
        changeBirthday(date, dateString) {
            console.log(date, dateString)
            this.userInfo.birthday = dateString;
        },
        handleAvatarSuccess(res, file) {
            console.log('upload success:', res, file)
            // debugger
            if (res.code === '200') {
                this.userInfo.avatar = res.data.key;
                this.$message.success('上传成功');
            } else {
                // this.$message.error('上传失败');
            }
        },
        uploadFiled(err) {
            this.$message.error('上传失败');
            console.log(err)
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'jpg' || 'png';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG和png 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        saveUserInfo() {
            this.spinning = true;
            service.post(service.uri.user.saveUserInfo, this.userInfo).then(() => {
                this.spinning = false;
                let userCache = JSON.parse(localStorage.getItem('userInfo'));
                userCache.nickname = this.userInfo.nickname;
                localStorage.setItem('userInfo', JSON.stringify(userCache));
                this.$message.success("数据保存成功");
            });
        }

    }
}
</script>

<style lang = "less" scoped>
.body {
    background: #fbfbfb;
}

.page-wrap {
    width: 1200px;
    margin: 0 auto;
}
a {
    color: #de0000;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color .3s;
    -webkit-text-decoration-skip: objects;
}

dt {
    font-weight: 500;
}

dd {
    margin-bottom: 0.5em;
    margin-left: 0;
}

.body {
    background: #fbfbfb;

    .page-wrap {
        width: 1200px;
        margin: 0 auto;
        display: flex;
    }
}

    .container {
        font-size: 14px;
        font-weight: 400;
        color: #333;
        border: 0 solid #ededed;
        background: #fff;
        min-height: 580px;
        padding: 20px 0 0;
    }

    .nav-title {
        color: #333;
        font-size: 14px;
        font-weight: 500;
        line-height: 50px;
        padding: 0 20px;
    }

    .modal-footer {
        margin: 0;
        padding: 30px 0 70px;
        text-align: left;
        border: none;
        display: flex;
        justify-content: center;

        .ant-btn {
            width: 200px;
            height: 48px;
        }

        .last-child {
            margin-left: 40px;
            background: #de0000;
            border: 1px solid #de0000;
            color: #fff;
        }
    }

</style>